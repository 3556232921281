import React from "react"
import { graphql } from "gatsby"
import PostList from "../components/postlist"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = ' Welcome to codingfreaks!';
    const posts = data.allMarkdownRemark.edges
    const introduction = (
      <span>
        <p>
          This page is all about development in the ever growing eco-system of Microsoft. We'll cover a lot of stuff from Azure, .NET (Core), C# and many more. 
        </p>
      </span>
    );

    return (
      <PostList posts={posts} siteTitle={siteTitle} introduction={introduction} />
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {postType: {eq: "Released"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            postType
            language
          }
        }
      }
    }
  }
`
